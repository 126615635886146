import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c(VContainer,{attrs:{"fluid":""}},[_c('scae-breadcrumbs'),_c(VResponsive,{staticClass:"mx-auto mb-4",attrs:{"max-width":"800"}},[_c(VCard,{staticClass:"overflow-y-auto show-on-print",attrs:{"max-height":"600"}},[_c(VCardText,{staticClass:"font-weight-bold",domProps:{"innerHTML":_vm._s(_vm.termoEmHtml)}})],1),_c(VContainer,{staticClass:"px-0",attrs:{"fluid":""}},[_c(VCheckbox,{attrs:{"disabled":_vm.usuario.termoLgpdAceito},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Eu "+_vm._s(_vm.$user.fullName)+", CPF Nº "+_vm._s(_vm.$formatCpf(_vm.$user.username))+", concordo com os itens listados do "),_c('span',{staticClass:"blue--text"},[_vm._v(" Termo de consentimento do uso de dados pessoais. ")])])]},proxy:true}]),model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})],1),(!_vm.usuario.termoLgpdAceito)?_c('scae-buttons',{attrs:{"textAction":'Confirmar',"disableAction":!_vm.checkbox},on:{"click":_vm.aceitarTermo,"cancel":_vm.logout}}):_c(VRow,{staticClass:"d-block text-center btn-group"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$back()}}},[_vm._v(" Voltar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }