<template>
  <div class="page">
    <v-container fluid>
      <scae-breadcrumbs></scae-breadcrumbs>
      <v-responsive max-width="800" class="mx-auto mb-4">
        <v-card class="overflow-y-auto show-on-print" max-height="600">
          <v-card-text
            class="font-weight-bold"
            v-html="termoEmHtml"
            ></v-card-text>
        </v-card>
        <v-container class="px-0" fluid>
          <v-checkbox v-model="checkbox" :disabled="usuario.termoLgpdAceito">
            <template v-slot:label>
              <div>
                Eu {{ $user.fullName }}, CPF Nº {{ $formatCpf($user.username) }},
                 concordo com os itens listados do
                <span class="blue--text">
                  Termo de consentimento do uso de dados pessoais.
                </span>
              </div>
            </template>
          </v-checkbox>
        </v-container>
        <scae-buttons v-if="!usuario.termoLgpdAceito"
          @click="aceitarTermo"
          @cancel="logout"
          :textAction="'Confirmar'"
          :disableAction="!checkbox"
        ></scae-buttons>
        <v-row class="d-block text-center btn-group" v-else>
          <v-col cols="12">
            <v-btn 
              color="primary"
              @click="$back()"
              >
              Voltar
            </v-btn>
          </v-col>
        </v-row>
      </v-responsive>
    </v-container>
  </div>
</template>

<script>
import pageLoadingMixin from "mixins/page-loading";
import { AUTH_NAMESPACE, USER, LOGIN, UPDATE_ABILITIES, UPDATE_MENU } from 'store/modules/auth';
import configurations from '~/commons/configurations';
import { ConvertPermissionsIntoAbilities } from "~/services/abilities/ability";

export default {
  name: "ConsentimentoDadosPessoaisView",
  computed: {
    user() {
      return this.$store.getters[`${AUTH_NAMESPACE}/${USER}/${LOGIN}`];
    },
  termoEmHtml() {
      return (this.html || '')
      .replaceAll("{{DataAtual}}", this.$dayjs().format('DD/MM/YYYY HH:mm'))
      .replaceAll("{{NomeCompleto}}", this.$user.fullName)
      .replaceAll("{{Cpf}}", this.$formatCpf(this.$user.username))
      .replaceAll("{{Email}}", this.$user.email)
      .replaceAll("{{TelefonePessoal}}", this.$user.call || '-');
    }
  },
  mixins: [pageLoadingMixin],
  beforeMount() {
    this.hidePageLoading();
  },
  data: () => ({
    checkbox: false,
    html: "",
    termo: {
      termoId: ``,
      conteudo: ``,
    },
    usuario: {
      cpf: "",
      nome: "",
      vinculos: [],
      termoLgpdAceito: null,
    },
  }),
  methods: {
    aceitarTermo() {
      let mensagem = '';
      this.$aceitarTermo({
        nomeDeUsuario: this.$user.username,
      }).then(({data}) => {
        if(data.sucesso) {
          mensagem = data.mensagem
          return this.refreshTokenUi();
        }
        throw data.mensagem
      }).then(() => {
          return this.$api().get(configurations.api.auth.getMenuUri(this.$user.username));
        })
        .then(({ data }) => {
          return this[UPDATE_MENU](this.getTransformedMenu(data.itens));
        })
        .then(() => {
          return this.$api().get(configurations.api.auth.getUserPermissionsUri(this.$user.username));
        })
        .then(({ data }) => {
          const abilities = ConvertPermissionsIntoAbilities(data.permissoes);
          this[UPDATE_ABILITIES](abilities);
          return this.updateAbility(abilities);
        }).then(() => {
        return this.$atualizarCacheMenu()
      }).then(() => {
        this.notifySuccess(mensagem);
        this.$toHome();
      }).catch((err) => {
        this.notifyError(err);
      });
    },
    refreshTokenUi() {
      this.$api().get(configurations.api.auth.refreshTokenUri)
          .then(({ data }) => {
            if (data.sucesso) {
              return this[LOGIN](data);
            }
      })
    },
    updateAbility(permissions) {
      this.$ability.update(permissions);
    },
    getTransformedMenu(grupos) {
      if (grupos) {
        let menu = [];

        for (let grupo of grupos) {
          let menuItem = {
            icon: grupo.icone,
            name: grupo.nomeDeExibicao,
            url: grupo.endereco,
            items: [],
          };

          if (Array.isArray(grupo.itens)) {
            for (let item of grupo.itens) {
              menuItem.items.push({
                icon: item.icone,
                name: item.nomeDeExibicao,
                url: item.endereco,
              });
            }

            menu.push(menuItem);
          }
        }
        return menu;
      }

      return [];
    },
    async logout() {
      this.$router.push({ path: "/login" });
    },
  },
  mounted() {
    this.$obterTermo({ params: { nomeDeUsuario: this.$user.username } }).then(({ data }) => {
      this.termo = data.termo;
      this.html = data.html;
    });
  },
};
</script>